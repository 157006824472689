// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/aboutPage.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-careers-page-js": () => import("./../src/templates/careersPage.js" /* webpackChunkName: "component---src-templates-careers-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../src/templates/contactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-machines-page-js": () => import("./../src/templates/machinesPage.js" /* webpackChunkName: "component---src-templates-machines-page-js" */),
  "component---src-templates-process-page-js": () => import("./../src/templates/processPage.js" /* webpackChunkName: "component---src-templates-process-page-js" */),
  "component---src-templates-solutions-page-js": () => import("./../src/templates/solutionsPage.js" /* webpackChunkName: "component---src-templates-solutions-page-js" */)
}

